<ion-item [button]="true" [detail]="false">

    <ng-content select="[slot='start']" />

    <div class="fish-item" slot="start">
        @if (showPhoto) {
            <ion-thumbnail>
                <ion-spinner [class.hidden]="! pendingFish || ! pendingFish.uploading" class="ion-color ion-color-light" />
                <img [src]="fish?.photo || pendingFish?.photoFileUri" alt="" />
            </ion-thumbnail>
        }

        @if (fish?.length || isTopCatch) {
            <div class="fish-item__data">
                @if (fish?.length) {
                    <div class="ion-font-size-3">
                        {{ fish.length }}
                        <span class="ion-font-size-1">mm</span>
                    </div>
                }

                @if (isTopCatch) {
                    <ion-badge slot="end" class="ion-color ion-color-secondary">
                        Top catch
                    </ion-badge>
                }
            </div>
        }
    </div>

    <ion-label class="ion-text-wrap">
        @if (fish?.contestFish) {
            <div>
                <strong>{{ fish.contestFish.name }}</strong>
            </div>
        }
        @if (team) {
            <div>{{ team.name }}</div>
            <div class="ion-text-muted ion-font-size-2">
                {{ team.users | userNames }}
            </div>
        }
    </ion-label>

    @if (showFishStatus && fish?.state) {
        @if (fish.state === FishStatusEnum.Approved) {
            <ion-badge slot="end" class="ion-color ion-color-success">Approved</ion-badge>
        } @else if (fish.state === FishStatusEnum.Rejected) {
            <ion-badge slot="end" class="ion-color ion-color-danger">Rejected</ion-badge>
        } @else {
            <ion-badge slot="end" class="ion-color ion-color-secondary">Pending approval</ion-badge>
        }
    }

    <ng-content select="[slot='end']" />

</ion-item>
