import { HttpClientModule } from '@angular/common/http'
import { ErrorHandler, isDevMode, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouteReuseStrategy } from '@angular/router'
import { ServiceWorkerModule } from '@angular/service-worker'
import Bugsnag from '@bugsnag/js'
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'
import { IonicModule, IonicRouteStrategy } from '@ionic/angular'
import { IonicStorageModule } from '@ionic/storage-angular'
import { CacheModule } from 'ionic-cache'

import { AppConfig } from '@app-config'
import { MenuComponentsModule } from '@app-components/menu/menu-components.module'
import { SponsorsComponentsModule } from '@app-components/sponsors/sponsors-components.module'
import { UiDirectivesModule } from '@app-directives/ui/ui-directives.module'
import { GraphQLModule } from '@app-modules/graphql.module'
import { NativeServiceModule } from '@app-services/native/native-service.module'
import { NavigationServiceModule } from '@app-services/navigation/navigation.service.module'
import { StorageServiceModule } from '@app-services/storage/storage-service.module'
import { AppRoutingModule } from '@app/app-routing.module'
import { AppComponent } from '@app/app.component'
import { NavigationDirectivesModule } from '@app-directives/navigation/navigation-directives.module'
import packageJson from '@project-root/package.json'

/**
 * Bugsnag
 */
const errorHandlerProviders = []

if (AppConfig.bugsnag?.enabled) {
    function errorHandlerFactory() {
        return new BugsnagErrorHandler()
    }

    Bugsnag.start({
        apiKey: AppConfig.bugsnag.apiKey,
        appVersion: (AppConfig.environment === 'production') ? packageJson.version : 'staging',
        appType: (window as any).Capacitor?.isNativePlatform() ? 'app' : 'browser',
        redactedKeys: [
            'token',
            'password',
            'currentPassword',
        ],
        onError: (event) => ! event.originalError.message?.includes('ApolloError'),
    })

    errorHandlerProviders.push({
        provide: ErrorHandler,
        useFactory: errorHandlerFactory,
    })
}

/**
 * App module
 */
@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        CacheModule.forRoot(),
        GraphQLModule,
        HttpClientModule,
        IonicModule.forRoot({
            mode: (/(iphone|ipad|ipod|macintosh|mac os)/i).test(navigator.userAgent) ? 'ios' : 'md',
            animated: ! window.matchMedia('(prefers-reduced-motion: reduce)')?.matches,
            swipeBackEnabled: (/cordova|capacitor|ionic/i).test(navigator.userAgent),
        }),
        IonicStorageModule.forRoot({
            name: 'predatortour',
        }),
        MenuComponentsModule,
        NativeServiceModule,
        NavigationDirectivesModule,
        NavigationServiceModule,
        SponsorsComponentsModule,
        StorageServiceModule,
        UiDirectivesModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: ! isDevMode(),
            registrationStrategy: 'registerWhenStable:30000',
        }),
    ],
    providers: [
        ...errorHandlerProviders,
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy,
        },
        HttpClientModule,
    ],
    bootstrap: [
        AppComponent,
    ],
})
export class AppModule {
}
