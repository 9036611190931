<ion-header>
    <ion-toolbar class="ion-color ion-color-primary">
        <ion-buttons slot="start">
            <ion-back-button defaultHref="/auth/login" />
        </ion-buttons>
        <ion-title>Forgot password</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content [forceOverscroll]="false">

    <form (submit)="submit()"
          [formGroup]="form!"
          action=""
          class="tablet-card"
    >

        <ion-card class="ion-margin ion-color ion-color-light">
            <ion-card-content>
                <p>
                    Enter your email address and press the 'send' button to receive a link by e-mail to
                    reset your password.
                </p>
            </ion-card-content>
        </ion-card>

        <ion-list>
            <ion-item>
                <ion-input autocomplete="email"
                           enterkeyhint="send"
                           errorText="Please enter a valid email address"
                           fill="outline"
                           formControlName="email"
                           label="Email address"
                           labelPlacement="stacked"
                           type="email"
                />
            </ion-item>
        </ion-list>

        <ion-card [class.hidden]="! apiError" class="ion-color ion-color-danger ion-margin">
            <ion-card-content class="error-message">
                {{ apiError }}
            </ion-card-content>
        </ion-card>

        <input class="ion-semi-hidden" tabindex="-1" type="submit" value="" />
        <ion-button class="ion-margin ion-color ion-color-primary"
                    enterkeyhint="send"
                    expand="block"
                    size="large"
                    type="submit"
        >
            <span [class.hidden]="loading">Send</span>
            <ion-icon [class.hidden]="loading" name="arrow-forward-outline" slot="end" />
            <ion-spinner [class.hidden]="! loading" slot="icon-only" />
        </ion-button>

    </form>

</ion-content>
