import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { ActionPerformed, PushNotifications } from '@capacitor/push-notifications'
import { FCM } from '@capacitor-community/fcm'
import { AlertButton, AlertController, Platform } from '@ionic/angular'
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings'
import { BehaviorSubject, lastValueFrom, Subject } from 'rxjs'

import { RegisterNotificationTokenMutation, RegisterNotificationTokenMutationService } from '@app-graphql'
import {
    ActionPerformedWithNotificationWithCustomData,
    NotificationType,
    NotificationWithCustomData,
} from '@app-interfaces'
import { AuthService, StorageService } from '@app-services'

enum NotificationTopics {
    Visitors = 'visitors',
    Participants = 'participants',
}

@Injectable({
    providedIn: 'root',
})
export class PushService {

    public hasPermission$ = new BehaviorSubject<boolean | null>(null)
    public token$ = new BehaviorSubject<string | null>(null)
    public notification$ = new Subject<NotificationWithCustomData>()
    public notificationAction$ = new Subject<ActionPerformedWithNotificationWithCustomData>()

    private currentlyRegisteredToken: string | null = null

    constructor(
        private readonly alertController: AlertController,
        private readonly authService: AuthService,
        private readonly platform: Platform,
        private readonly registerNotificationTokenMutationService: RegisterNotificationTokenMutationService,
        private readonly router: Router,
        private readonly storageService: StorageService,
    ) {
        this.platform.ready().then(() => this.initialize())
        this.platform.resume.subscribe(() => this.refreshPermissionState())
    }

    public async refreshPermissionState(): Promise<void> {
        const hasPermission = await this.hasPermission()
        this.hasPermission$.next(hasPermission)
    }

    public async hasPermission(): Promise<boolean> {
        if (! this.platform.is('hybrid')) {
            return false
        }

        const { receive } = await PushNotifications.checkPermissions()
        return receive === 'granted'
    }

    public async requestPermission(): Promise<void> {
        if (! this.platform.is('hybrid')) {
            return
        }

        // Open the app settings if the permission was already explicitly denied, otherwise request the permission
        const { receive } = await PushNotifications.checkPermissions()
        if (receive === 'denied') {
            await this.openNotificationPermissionSettings()
        } else {
            await PushNotifications.requestPermissions()
        }

        await PushNotifications.register()
        await this.refreshPermissionState()
    }

    public async getToken(): Promise<string | null> {
        if (! this.platform.is('hybrid')) {
            return null
        }

        const { token } = await FCM.getToken()
        this.token$.next(token)

        return token
    }

    public async unregister(): Promise<void> {
        if (! this.platform.is('hybrid')) {
            return
        }

        await PushNotifications.unregister()
    }

    public async subscribeToTopic(topic: string | NotificationTopics): Promise<void> {
        if (! this.platform.is('hybrid')) {
            return
        }

        try {
            await FCM.subscribeTo({ topic })
        } catch (e) {
            // Ignore errors when subscribing to a topic that we are already subscribed to
        }
    }

    public async unsubscribeFromTopic(topic: string | NotificationTopics): Promise<void> {
        if (! this.platform.is('hybrid')) {
            return
        }

        try {
            await FCM.unsubscribeFrom({ topic })
        } catch (e) {
            // Ignore errors when unsubscribing from a topic that we are not subscribed to
        }
    }

    public async openNotificationPermissionSettings(): Promise<void> {
        await NativeSettings.open({
            optionAndroid: AndroidSettings.AppNotification,
            optionIOS: IOSSettings.Notifications,
        })
    }

    private async initialize(): Promise<void> {
        if (! this.platform.is('hybrid')) {
            return
        }

        this.setTokenHandlers()
        this.setNotificationHandlers()

        await Promise.all([
            FCM.setAutoInit({ enabled: true }),
            this.createChannelsIfNeeded(),
            this.refreshPermissionState(),
            this.getToken(),
        ])
    }

    private setTokenHandlers(): void {
        // Register token when it is available and the user is logged in
        this.token$.subscribe(async (token) => {
            if (! token) {
                return
            }

            const loggedIn = await this.authService.isAuthenticated()
            if (loggedIn) {
                if (token !== this.currentlyRegisteredToken) {
                    this.currentlyRegisteredToken = token
                    await this.registerNotificationToken(token)

                    await Promise.all([
                        this.unsubscribeFromTopic(NotificationTopics.Visitors),
                        this.subscribeToTopic(NotificationTopics.Participants),
                    ])
                }
            } else {
                this.currentlyRegisteredToken = null
                await Promise.all([
                    this.unsubscribeFromTopic(NotificationTopics.Participants),
                    this.subscribeToTopic(NotificationTopics.Visitors),
                ])
            }
        })

        // Auto-(un)subscribe to/from relevant topics when the user logs in or out
        this.authService.loginStateChanged$.subscribe(async (loggedIn) => {
            if (loggedIn) {
                await Promise.all([
                    this.unsubscribeFromTopic(NotificationTopics.Visitors),
                    this.subscribeToTopic(NotificationTopics.Participants),
                ])
            } else {
                await Promise.all([
                    this.unsubscribeFromTopic(NotificationTopics.Participants),
                    this.subscribeToTopic(NotificationTopics.Visitors),
                ])
            }
        })

        // Remove token when the user logs out
        this.authService.loginStateChanged$.subscribe(async (loggedIn) => {
            const token = await this.getToken()
            if (! token || loggedIn) {
                return
            }
            await this.unregisterNotificationToken(token)
            await this.unregister()
        })
    }

    private setNotificationHandlers(): void {
        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener('pushNotificationReceived', async (notification: NotificationWithCustomData) => {
            console.log('Push notification received', notification)
            await this.handleNotificationAction(notification)
            this.notification$.next(notification)
        })

        // Method called when tapping on a notification
        PushNotifications.addListener('pushNotificationActionPerformed', async (actionPerformed: ActionPerformed) => {
            console.log('Push notification action performed', actionPerformed)
            if (actionPerformed.actionId === 'tap') {
                await this.handleNotificationAction(actionPerformed.notification, true)
            }
            this.notificationAction$.next(actionPerformed)
        })
    }

    private async handleNotificationAction(
        notification: NotificationWithCustomData,
        fromBackground = false,
    ): Promise<void> {
        if (notification.data.cacheToClear) {
            await Promise.all(
                notification.data.cacheToClear.map((groupKey) => this.storageService.removeGroup(groupKey)),
            )
        }

        if (notification.data.route) {
            await this.handleOtherNotification(notification, fromBackground)

        } else if (notification.data.type === NotificationType.Message) {
            await this.handleMessageNotification(notification)

        } else if (notification.data.type === NotificationType.News) {
            await this.handleNewsNotification(notification)

        } else if (notification.data.type === NotificationType.FishStatus) {
            await this.handleFishStatusNotification(notification)
        }
    }

    private async handleOtherNotification(
        notification: NotificationWithCustomData,
        fromBackground = false,
    ): Promise<void> {
        const route = notification.data.route.split('?')[0]
        const queryParams = new URLSearchParams(notification.data.route.split('?')[1])

        if (fromBackground) {
            await this.router.navigate([route], { queryParams })
        } else {
            const alert = await this.alertController.create({
                header: notification.title,
                message: notification.body,
                buttons: [
                    'Close',
                    { text: 'View', handler: () => this.router.navigate([route], { queryParams }) },
                ],
            })
            await alert.present()
        }
    }

    private async handleMessageNotification(notification: NotificationWithCustomData): Promise<void> {
        await this.storageService.removeGroup('message')

        const buttons: (string | AlertButton)[] = ['Close']

        if (notification.data.itemId) {
            buttons.push({
                text: 'Read more',
                handler: () => this.router.navigate([`/messages/${notification.data.itemId}`]),
            })
        }

        const alert = await this.alertController.create({
            header: notification.title,
            message: notification.body,
            buttons,
        })
        await alert.present()
    }

    private async handleNewsNotification(notification: NotificationWithCustomData): Promise<void> {
        await this.storageService.removeGroup('news')

        const buttons: (string | AlertButton)[] = ['Close']

        if (notification.data.contestId && notification.data.itemId) {
            buttons.push({
                text: 'Read more',
                handler: () => this.router.navigate([`/contest/${notification.data.contestId}/news/${notification.data.itemId}`]),
            })
        }

        const alert = await this.alertController.create({
            header: notification.title,
            message: notification.body,
            buttons,
        })
        await alert.present()
    }

    private async handleFishStatusNotification(notification: NotificationWithCustomData): Promise<void> {
        await this.storageService.removeGroup('user')

        const alert = await this.alertController.create({
            header: notification.title,
            message: notification.body,
            buttons: [
                'Close',
                {
                    text: 'View my fish',
                    handler: () => this.router.navigateByUrl('/account/fish'),
                },
            ],
        })
        await alert.present()
    }

    private async createChannelsIfNeeded(): Promise<void> {
        if (! this.platform.is('android')) {
            return
        }

        const { channels } = await PushNotifications.listChannels()
        const hasDefaultChannel = !! channels.some((channel) => channel.id === 'default')

        if (! hasDefaultChannel) {

            // Delete old default channel
            await PushNotifications.deleteChannel({ id: 'fcm_default_channel' })

            await PushNotifications.createChannel({
                id: 'default',
                name: 'Push notifications',
                description: 'Receive notifications',
                importance: 4,
                visibility: 1,
            })
        }
    }

    private async registerNotificationToken(token: string): Promise<RegisterNotificationTokenMutation> {
        try {
            const response = await lastValueFrom(
                this.registerNotificationTokenMutationService.mutate({ token, delete: false }),
            )

            return response.data as RegisterNotificationTokenMutation
        } catch (e: any) {
            //
        }
    }

    private async unregisterNotificationToken(token: string): Promise<RegisterNotificationTokenMutation> {
        try {
            const response = await lastValueFrom(
                this.registerNotificationTokenMutationService.mutate({ token, delete: true }),
            )

            return response.data as RegisterNotificationTokenMutation
        } catch (e: any) {
            //
        }
    }

}
