import { Component, OnDestroy, OnInit } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { SplashScreen } from '@capacitor/splash-screen'
import { Platform } from '@ionic/angular'
import { Subscription } from 'rxjs'

import packageJson from '@project-root/package.json'

import { BackButtonService, GeneralService, StatusBarStyleService, UserService } from '@app-services'
import { GeneralData, User } from '@app-graphql'

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnDestroy, OnInit {

    public generalData: Partial<GeneralData> | null = null
    public user: Partial<User> | null = null
    public appVersion: string = packageJson.version
    public activeMenuGroup: string | 'contest'

    private generalData$: Subscription | null = null
    private user$: Subscription | null = null

    constructor(
        private readonly backButtonService: BackButtonService,
        private readonly generalService: GeneralService,
        private readonly statusBarStyleService: StatusBarStyleService,
        private readonly platform: Platform,
        private readonly router: Router,
        private readonly userService: UserService,
    ) {
        this.generalData$ = this.generalService.generalData$.subscribe((generalData: Partial<GeneralData> | null) => {
            this.generalData = generalData
        })

        this.user$ = this.userService.user$.subscribe((user: Partial<User> | null) => {
            this.user = user
        })

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.activeMenuGroup = event.url.split('/')?.[1] || 'contest'
            }
        })
    }

    public async ngOnInit(): Promise<void> {
        await this.platform.ready()
        await this.initialize()
    }

    public ngOnDestroy(): void {
        this.generalData$?.unsubscribe()
        this.user$?.unsubscribe()
    }

    protected async initialize(): Promise<void> {
        this.backButtonService.initialize()

        try {
            await Promise.all([
                this.generalService.getGeneralData(),
                this.userService.getUser(),
            ])
        } catch (e) {
            //
        }

        if (this.platform.is('hybrid')) {
            await SplashScreen.hide()
            await this.statusBarStyleService.initialize()
        }
    }

}
