<ion-header>
    <ion-toolbar class="ion-color ion-color-primary">
        @if (! isStandalone) {
            <ion-buttons slot="start">
                <ion-back-button />
                <ion-menu-button />
            </ion-buttons>
        }

        <ion-title>Leaderboard</ion-title>

        @if (loading) {
            <ion-progress-bar type="indeterminate" />
        }

        <ion-buttons slot="end">
            <ion-button (click)="refresh()">
                <ion-icon name="refresh-outline" slot="icon-only" />
            </ion-button>
        </ion-buttons>
    </ion-toolbar>

    <ion-searchbar #searchbar
                   (ionInput)="filterList($any($event))"
                   [animated]="true"
                   [debounce]="300"
                   class="ion-color ion-color-light tablet-max-600"
                   placeholder="Search by team or member"
    />

</ion-header>

<ion-content>

    <ion-refresher (ionRefresh)="refresh($any($event))" slot="fixed">
        <ion-refresher-content />
    </ion-refresher>

    @if (leaderboard?.contest && leaderboard?.leaderboardEntries?.length) {
        <div class="ion-flex ion-h-100 tablet-max-600 ion-margin-bottom">
            <ion-list lines="inset">
                @for (leaderboardEntry of leaderboard.leaderboardEntries; track leaderboardEntry) {
                    <ion-item [button]="true"
                              [detail]="true"
                              [class.hidden]="
                                  searchResults
                                  && ! searchResults.includes(
                                      leaderboardEntry.team.name + ' ' + (leaderboardEntry.team.users | userNames)
                                  )
                              "
                              [routerLink]="(
                                  isStandalone
                                      ? ('/contest/' + leaderboard.contest.id + '/standalone-leaderboard/' + leaderboardEntry.team.id)
                                      : (
                                          myTeamIds?.includes(leaderboardEntry.team.id)
                                              ? '/account/my-team'
                                              : ('/contest/' + leaderboard.contest.id + '/leaderboard/' + leaderboardEntry.team.id)
                                      )
                              )"
                    >
                        <div class="team-score" slot="start">
                            <div class="team-score__rank">
                                <div class="ion-font-size-5">{{ leaderboardEntry.rank }}</div>
                                <app-contest-score-badge [leaderboardEntry]="leaderboardEntry" />
                            </div>
                            <div class="team-score__points">
                                {{ leaderboardEntry.points }}
                                <div class="ion-font-size-1">Points</div>
                                @if (leaderboardEntry.hasBonus) {
                                    <ion-badge class="ion-color ion-color-primary ion-margin-top-2">Bonus</ion-badge>
                                }
                            </div>
                        </div>
                        <ion-label class="ion-text-wrap">
                            <div>{{ leaderboardEntry.team.name }}</div>
                            <div class="ion-text-muted ion-font-size-2">
                                {{ leaderboardEntry.team.users | userNames }}
                            </div>
                        </ion-label>
                    </ion-item>
                }
            </ion-list>

            @if (searchbar.value) {
                <app-empty icon="search-outline" class="ion-margin-vertical-4">
                    No
                    @if (searchResults?.length) {
                        other
                    }
                    results for &lsquo;{{ searchbar.value }}&rsquo;
                    <ion-button size="small"
                                class="ion-color ion-color-light ion-margin-top-3"
                                (click)="searchbar.value = null; filterList()"
                    >
                        <ion-icon slot="start" name="close-outline" />
                        Clear search
                    </ion-button>
                </app-empty>
            }
        </div>
    } @else if (! loading) {
        <app-empty icon="podium-outline">
            No results (yet)
        </app-empty>
    }

</ion-content>
