<ion-header>
    <ion-toolbar class="ion-color ion-color-primary">
        <ion-buttons slot="start">
            <ion-back-button />
            <ion-menu-button />
        </ion-buttons>
        <ion-title>Login</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content [forceOverscroll]="false">

    <form (submit)="submit()"
          [formGroup]="form!"
          action=""
          class="tablet-card"
    >

        <ion-card class="ion-margin ion-color ion-color-light">
            <ion-card-content>
                <div>Use the credentials we sent you via email to sign in.</div>
                <div class="ion-margin-top-3">You will receive your credentials on the first day.</div>
            </ion-card-content>
        </ion-card>

        <ion-list>
            <ion-item>
                <ion-input autocomplete="email"
                           errorText="Please enter a valid email address"
                           fill="outline"
                           formControlName="email"
                           label="Email address"
                           labelPlacement="stacked"
                           type="email"
                />
            </ion-item>

            <ion-item>
                <ion-input #passwordInput
                           autocomplete="current-password"
                           errorText="Please enter your password"
                           fill="outline"
                           formControlName="password"
                           label="Password"
                           labelPlacement="stacked"
                           type="password"
                >
                    <ion-button (click)="passwordInput.type = (
                                    passwordInput.type === 'password' ? 'text' : 'password'
                                )"
                                class="ion-color ion-color-primary ion-no-margin"
                                fill="clear"
                                size="small"
                                slot="end"
                    >
                        <ion-icon [name]="passwordInput.type === 'password' ? 'eye' : 'eye-off'"
                                  slot="icon-only"
                        />
                    </ion-button>
                </ion-input>
            </ion-item>
        </ion-list>

        <ion-card [class.hidden]="! apiError" class="ion-color ion-color-danger ion-margin">
            <ion-card-content class="error-message">
                {{ apiError }}
            </ion-card-content>
        </ion-card>

        <input class="ion-semi-hidden" tabindex="-1" type="submit" value="" />
        <ion-button class="ion-margin ion-color ion-color-primary"
                    enterkeyhint="go"
                    expand="block"
                    size="large"
                    type="submit"
        >
            <span [class.hidden]="loading">Log in</span>
            <ion-icon [class.hidden]="loading" name="arrow-forward-outline" slot="end" />
            <ion-spinner [class.hidden]="! loading" slot="icon-only" />
        </ion-button>

        <hr class="ion-color ion-color-medium ion-margin ion-margin-top-5" />

        <div class="ion-margin">
            <ion-button [queryParams]="{ email: form?.controls?.email?.value }"
                        class="ion-color ion-color-light"
                        routerLink="/auth/forgot-password"
                        size="small"
            >
                I forgot my password...
            </ion-button>
        </div>

    </form>

</ion-content>
