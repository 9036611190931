import { Injectable } from '@angular/core'
import { NavController } from '@ionic/angular'
import { MutationResult } from 'apollo-angular'
import { CacheService } from 'ionic-cache'
import { lastValueFrom, Subject } from 'rxjs'

import {
    ForgotPasswordMutation,
    ForgotPasswordMutationService,
    LoginInput,
    LoginMutation,
    LoginMutationService,
    LogoutMutation,
    LogoutMutationService,
    ResendEmailVerificationInput,
    ResendEmailVerificationMutation,
    ResendEmailVerificationMutationService,
} from '@app-graphql'
import { ApiHelperService } from '@app-services/api/api-helper.service'
import { StorageService } from '@app-services/storage'

@Injectable({
    providedIn: 'root',
})
export class AuthService {

    public authenticationInProgress = false
    public logoutInProgress = false
    public userWasRedirectedAsAlreadyLoggedIn = false // This is to prevent infinite loops after gql errors
    public loginStateChanged$ = new Subject<boolean>()
    private token: string | null = null
    private initialized = false

    constructor(
        private readonly apiHelperService: ApiHelperService,
        private readonly cacheService: CacheService,
        private readonly forgotPasswordMutationService: ForgotPasswordMutationService,
        private readonly loginMutationService: LoginMutationService,
        private readonly logoutMutationService: LogoutMutationService,
        private readonly navController: NavController,
        private readonly resendEmailVerificationMutationService: ResendEmailVerificationMutationService,
        private readonly storageService: StorageService,
    ) {
    }

    public async initialize(): Promise<void> {
        if (this.initialized) {
            return
        }

        this.initialized = true
        this.authenticationInProgress = true

        await this.authenticateFromPersistedToken()
        this.authenticationInProgress = false
    }

    public async isAuthenticated(): Promise<boolean> {
        await this.initialize()

        return !! this.token
    }

    public getToken(): string | null {
        return this.token
    }

    public async login(input: LoginInput): Promise<LoginMutation> {
        try {
            const response = await lastValueFrom(
                this.loginMutationService.mutate({ input }),
            )
            await this.handleToken(response.data?.login?.token || null)

            if (response.data?.login?.token) {
                this.loginStateChanged$.next(true)
            }

            // Clear cache groups for requests that return responses based on the user's authentication status
            await this.apiHelperService.invalidateCache('notification')

            return response.data as LoginMutation
        } catch (e: any) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    public async forgotPassword(email: string): Promise<ForgotPasswordMutation> {
        try {
            const response = await lastValueFrom(
                this.forgotPasswordMutationService.mutate({ input: { email } }),
            )
            return response.data as ForgotPasswordMutation
        } catch (e: any) {
            await this.logout(true)
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    public async logout(redirectToLogin = false): Promise<LogoutMutation | null> {
        if (this.logoutInProgress) {
            return null
        }

        this.logoutInProgress = true
        let response: MutationResult<LogoutMutation> | null = null

        try {
            response = await lastValueFrom(this.logoutMutationService.mutate())
        } catch (e: any) {
        }

        this.token = null

        await Promise.all([
            this.apiHelperService.invalidateCache(),
            this.cacheService.clearAll(),
            this.storageService.remove('token'),
        ])

        this.userWasRedirectedAsAlreadyLoggedIn = false
        this.loginStateChanged$.next(false)

        if (redirectToLogin) {
            await this.navController.navigateBack('/auth/login', { replaceUrl: true })
        }

        this.logoutInProgress = false

        return (response?.data as LogoutMutation) || null
    }

    public async resendEmailVerification(
        input: ResendEmailVerificationInput,
    ): Promise<ResendEmailVerificationMutation> {
        try {
            const response = await lastValueFrom(
                this.resendEmailVerificationMutationService.mutate({ input }),
            )
            return response.data as ResendEmailVerificationMutation
        } catch (e: any) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    private async handleToken(token: string | null): Promise<void> {
        this.token = token
        if (this.token) {
            await this.storageService.set('token', this.token)
        }
    }

    private async authenticateFromPersistedToken(): Promise<void> {
        this.token = await this.storageService.get('token')
    }

}
