<ion-modal #modal
           [breakpoints]="breakpoints"
           [class.action-sheet-modal]="!! breakpoints"
           [handle]="handle"
           [initialBreakpoint]="initialBreakpoint"
           [trigger]="trigger"
>
    <ng-template>

        <ion-header>
            <ion-toolbar>
                <ion-title>Add fish</ion-title>
                <ion-buttons slot="end">
                    <ion-button (click)="modal?.dismiss()"
                                class="ion-color ion-color-primary"
                                fill="clear"
                    >
                        <ion-icon name="close" slot="icon-only" />
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content>

            <ion-loading #loadingElement [backdropDismiss]="true" />

            @if (loading) {
                <div class="ion-flex-center ion-margin-top-5">
                    <ion-spinner />
                </div>
            } @else {

                @if (contest) {
                    <form (submit)="saveFish()"
                          [formGroup]="form!"
                          action=""
                          class="tablet-card"
                          id="add-fish-form"
                    >

                        <ion-card class="ion-margin ion-color ion-color-light">
                            <ion-card-content>
                                <div>
                                    To confirm the location the fish was caught,
                                    please ensure your location services are enabled.
                                </div>
                            </ion-card-content>
                        </ion-card>

                        <ion-list>

                            <div>
                                <ion-item class="ion-margin-top-4">
                                    <ion-label>
                                        <div class="ion-font-weight-bold">Photo</div>
                                        <p class="error-text ion-padding-right-1"
                                           [class.hidden]="! form?.controls?.photo?.touched || form?.controls?.photo?.valid"
                                        >
                                            Please add a photo of the fish
                                        </p>
                                    </ion-label>

                                    <app-image-viewer-modal #imageViewerModal />

                                    <div class="photo-input">
                                        @if (photo) {
                                            <div class="photo-input__preview">
                                                <ion-button class="ion-color ion-color-light"
                                                            size="small"
                                                            (click)="clearPhoto()"
                                                >
                                                    <ion-icon name="close-outline" slot="icon-only" />
                                                </ion-button>
                                                @if (photo.webPath) {
                                                    <img [src]="photo.webPath"
                                                         alt=""
                                                         (click)="
                                                             imageViewerModal.images = [photo.webPath];
                                                             imageViewerModal.present()
                                                         "
                                                    />
                                                }
                                            </div>
                                        } @else {
                                            <ion-button class="ion-color ion-color-primary"
                                                        (click)="takePhoto(); modal.setCurrentBreakpoint(1)"
                                            >
                                                Take photo...
                                            </ion-button>
                                        }
                                    </div>
                                </ion-item>
                            </div>

                        </ion-list>

                        <input type="submit" tabindex="-1" value="" class="ion-semi-hidden" />

                    </form>

                    @if (contest.inTestmode) {
                        <ion-card class="ion-margin ion-color ion-color-primary">
                            <ion-card-content class="ion-flex ion-flex-row">
                                <ion-icon name="flask-outline"
                                          size="large"
                                          class="ion-flex-grow ion-margin-end"
                                />
                                <div>
                                    This contest is currently in test mode.
                                    Any photos you submit during test mode, will not count towards your score.
                                </div>
                            </ion-card-content>
                        </ion-card>
                    }

                } @else {
                    <app-empty icon="ribbon-outline" height="auto">
                        There is no contest running at the moment.
                    </app-empty>
                }
            }

        </ion-content>

        @if (contest) {
            <ion-footer>
                <ion-toolbar class="ion-text-center">
                    <ion-button type="button"
                                enterkeyhint="done"
                                expand="block"
                                size="large"
                                class="ion-margin ion-color ion-color-primary"
                                (click)="saveFish()"
                    >
                        Save
                        <ion-icon slot="end" name="arrow-forward-outline" />
                    </ion-button>
                </ion-toolbar>
            </ion-footer>
        }

    </ng-template>
</ion-modal>
