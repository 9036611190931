// noinspection JSUnusedGlobalSymbols

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
    ID: { input: string; output: string; }
    String: { input: string; output: string; }
    Boolean: { input: boolean; output: boolean; }
    Int: { input: number; output: number; }
    Float: { input: number; output: number; }
    /** A datetime string with format `Y-m-d H:i:s`, e.g. `2018-05-23 13:43:32`. */
    DateTime: { input: any; output: any; }
    /** Can be used as an argument to upload files using https://github.com/jaydenseric/graphql-multipart-request-spec */
    Upload: { input: any; output: any; }
}

export interface Contest {
    contestFish: Array<ContestFish>;
    endsAt: Scalars['DateTime']['output'];
    hoursTillClosing: Scalars['Int']['output'];
    id: Scalars['ID']['output'];
    inTestmode: Scalars['Boolean']['output'];
    isCurrentlyActive: Scalars['Boolean']['output'];
    name: Scalars['String']['output'];
    news: Array<News>;
    rules: Scalars['String']['output'];
    sponsors: Array<Sponsor>;
    startsAt: Scalars['DateTime']['output'];
}

export interface ContestFish {
    contest: Contest;
    id: Scalars['ID']['output'];
    maxAmount: Scalars['Int']['output'];
    minimumLength: Scalars['Int']['output'];
    name: Scalars['String']['output'];
    photo: Scalars['String']['output'];
    priority: Scalars['Int']['output'];
    requiredToContinue: Scalars['Int']['output'];
}

export interface DeletableFileInput {
    delete?: InputMaybe<Scalars['Boolean']['input']>;
    file?: InputMaybe<Scalars['Upload']['input']>;
}

export interface EmailVerificationResponse {
    status: EmailVerificationStatus;
}

export enum EmailVerificationStatus {
    /** VERIFIED */
    Verified = 'VERIFIED',
}

export interface Fish {
    contestFish?: Maybe<ContestFish>;
    id: Scalars['ID']['output'];
    latitude: Scalars['String']['output'];
    length?: Maybe<Scalars['Int']['output']>;
    longitude: Scalars['String']['output'];
    photo?: Maybe<Scalars['String']['output']>;
    state: FishStatusEnum;
    team: Team;
    uniqueId: Scalars['String']['output'];
    user: User;
}

export interface FishInput {
    contestFishId?: InputMaybe<Scalars['ID']['input']>;
    contestId: Scalars['ID']['input'];
    latitude: Scalars['String']['input'];
    length?: InputMaybe<Scalars['Int']['input']>;
    longitude: Scalars['String']['input'];
    photo: Scalars['Upload']['input'];
    teamId: Scalars['ID']['input'];
    uniqueId: Scalars['String']['input'];
}

export enum FishStatusEnum {
    Approved = 'APPROVED',
    Rejected = 'REJECTED',
    Test = 'TEST',
    Unchecked = 'UNCHECKED',
}

export interface ForgotPasswordInput {
    email: Scalars['String']['input'];
    reset_password_url?: InputMaybe<ResetPasswordUrlInput>;
}

export interface ForgotPasswordResponse {
    message?: Maybe<Scalars['String']['output']>;
    status: ForgotPasswordStatus;
}

export enum ForgotPasswordStatus {
    /** EMAIL_SENT */
    EmailSent = 'EMAIL_SENT',
}

export interface GeneralData {
    activeContest?: Maybe<Contest>;
    dynamicMenuItems: Array<Maybe<MenuWebContent>>;
    sponsors: Array<Maybe<MenuSponsor>>;
}

export interface Leaderboard {
    contest: Contest;
    leaderboardEntries: Array<Maybe<LeaderboardEntry>>;
}

export interface LeaderboardEntry {
    hasBonus: Scalars['Boolean']['output'];
    points: Scalars['Float']['output'];
    rank: Scalars['Int']['output'];
    rankStatus: RankStatusEnum;
    team: Team;
}

export interface LoginInput {
    email: Scalars['String']['input'];
    password: Scalars['String']['input'];
}

export interface LoginResponse {
    token: Scalars['String']['output'];
    user: User;
}

export interface LogoutResponse {
    message: Scalars['String']['output'];
    status: LogoutStatus;
}

export enum LogoutStatus {
    /** TOKEN_REVOKED */
    TokenRevoked = 'TOKEN_REVOKED',
}

export interface MenuSponsor {
    id: Scalars['ID']['output'];
    logo?: Maybe<Scalars['String']['output']>;
    name: Scalars['String']['output'];
}

export interface MenuWebContent {
    id: Scalars['ID']['output'];
    title: Scalars['String']['output'];
    url?: Maybe<Scalars['String']['output']>;
}

export interface Message {
    content: Scalars['String']['output'];
    createdAt: Scalars['DateTime']['output'];
    fcmTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    id: Scalars['ID']['output'];
    message: Scalars['String']['output'];
    title: Scalars['String']['output'];
    topic?: Maybe<Scalars['String']['output']>;
    updatedAt: Scalars['DateTime']['output'];
}

export interface MessageEntry {
    createdAt: Scalars['DateTime']['output'];
    id: Scalars['ID']['output'];
    message: Scalars['String']['output'];
    title: Scalars['String']['output'];
    topic?: Maybe<Scalars['String']['output']>;
}

/** A paginated list of MessageEntry items. */
export interface MessageEntryPaginator {
    /** A list of MessageEntry items. */
    data: Array<MessageEntry>;
    /** Pagination information about the list of items. */
    paginatorInfo: PaginatorInfo;
}

export interface Mutation {
    forgotPassword: ForgotPasswordResponse;
    login: LoginResponse;
    logout: LogoutResponse;
    register: RegisterResponse;
    registerFish: Fish;
    registerNotificationToken: Scalars['Boolean']['output'];
    resendEmailVerification: ResendEmailVerificationResponse;
    resetPassword: ResetPasswordResponse;
    updatePassword: UpdatePasswordResponse;
    verifyEmail: EmailVerificationResponse;
}


export interface MutationForgotPasswordArgs {
    input: ForgotPasswordInput;
}


export interface MutationLoginArgs {
    input?: InputMaybe<LoginInput>;
}


export interface MutationRegisterArgs {
    input?: InputMaybe<RegisterInput>;
}


export interface MutationRegisterFishArgs {
    input: FishInput;
}


export interface MutationRegisterNotificationTokenArgs {
    delete?: Scalars['Boolean']['input'];
    token: Scalars['String']['input'];
}


export interface MutationResendEmailVerificationArgs {
    input: ResendEmailVerificationInput;
}


export interface MutationResetPasswordArgs {
    input: ResetPasswordInput;
}


export interface MutationUpdatePasswordArgs {
    input: UpdatePasswordInput;
}


export interface MutationVerifyEmailArgs {
    input: VerifyEmailInput;
}

export interface News {
    content: Scalars['String']['output'];
    contest: Contest;
    createdAt: Scalars['DateTime']['output'];
    id: Scalars['ID']['output'];
    image?: Maybe<Scalars['String']['output']>;
    isActive: Scalars['Boolean']['output'];
    title: Scalars['String']['output'];
    updatedAt: Scalars['DateTime']['output'];
}

/** Allows ordering a list of records. */
export interface OrderByClause {
    /** The column that is used for ordering. */
    column: Scalars['String']['input'];
    /** The direction that is used for ordering. */
    order: SortOrder;
}

/** Aggregate functions when ordering by a relation without specifying a column. */
export enum OrderByRelationAggregateFunction {
    /** Amount of items. */
    Count = 'COUNT',
}

/** Aggregate functions when ordering by a relation that may specify a column. */
export enum OrderByRelationWithColumnAggregateFunction {
    /** Average. */
    Avg = 'AVG',
    /** Amount of items. */
    Count = 'COUNT',
    /** Maximum. */
    Max = 'MAX',
    /** Minimum. */
    Min = 'MIN',
    /** Sum. */
    Sum = 'SUM',
}

/** Information about pagination using a fully featured paginator. */
export interface PaginatorInfo {
    /** Number of items in the current page. */
    count: Scalars['Int']['output'];
    /** Index of the current page. */
    currentPage: Scalars['Int']['output'];
    /** Index of the first item in the current page. */
    firstItem?: Maybe<Scalars['Int']['output']>;
    /** Are there more pages after this one? */
    hasMorePages: Scalars['Boolean']['output'];
    /** Index of the last item in the current page. */
    lastItem?: Maybe<Scalars['Int']['output']>;
    /** Index of the last available page. */
    lastPage: Scalars['Int']['output'];
    /** Number of items per page. */
    perPage: Scalars['Int']['output'];
    /** Number of total available items. */
    total: Scalars['Int']['output'];
}

export interface Query {
    contest: Contest;
    generalData: GeneralData;
    leaderboard: Leaderboard;
    me?: Maybe<User>;
    message: Message;
    messages: MessageEntryPaginator;
    news: Array<News>;
    newsItem: News;
    sponsor: Sponsor;
    sponsors: Array<Sponsor>;
    team: Team;
    teams: TeamPaginator;
    topCatches: TopCatches;
    user?: Maybe<User>;
    users: UserPaginator;
    webContent: WebContent;
}


export interface QueryContestArgs {
    id?: InputMaybe<Scalars['ID']['input']>;
}


export interface QueryLeaderboardArgs {
    contestId: Scalars['ID']['input'];
}


export interface QueryMessageArgs {
    id: Scalars['ID']['input'];
}


export interface QueryMessagesArgs {
    first?: Scalars['Int']['input'];
    page?: InputMaybe<Scalars['Int']['input']>;
}


export interface QueryNewsArgs {
    contestId: Scalars['ID']['input'];
}


export interface QueryNewsItemArgs {
    id: Scalars['ID']['input'];
}


export interface QuerySponsorArgs {
    id: Scalars['ID']['input'];
}


export interface QuerySponsorsArgs {
    contestId: Scalars['ID']['input'];
}


export interface QueryTeamArgs {
    id?: InputMaybe<Scalars['ID']['input']>;
}


export interface QueryTeamsArgs {
    first?: Scalars['Int']['input'];
    name?: InputMaybe<Scalars['String']['input']>;
    page?: InputMaybe<Scalars['Int']['input']>;
}


export interface QueryTopCatchesArgs {
    contestId: Scalars['ID']['input'];
    limit?: InputMaybe<Scalars['Int']['input']>;
}


export interface QueryUserArgs {
    email?: InputMaybe<Scalars['String']['input']>;
    id?: InputMaybe<Scalars['ID']['input']>;
}


export interface QueryUsersArgs {
    email?: InputMaybe<Scalars['String']['input']>;
    first?: Scalars['Int']['input'];
    firstName?: InputMaybe<Scalars['String']['input']>;
    lastName?: InputMaybe<Scalars['String']['input']>;
    page?: InputMaybe<Scalars['Int']['input']>;
}


export interface QueryWebContentArgs {
    id: Scalars['ID']['input'];
}

export enum RankStatusEnum {
    Bronze = 'BRONZE',
    Gold = 'GOLD',
    None = 'NONE',
    Silver = 'SILVER',
}

export interface RegisterInput {
    email: Scalars['String']['input'];
    name: Scalars['String']['input'];
    password: Scalars['String']['input'];
    password_confirmation: Scalars['String']['input'];
    verification_url?: InputMaybe<VerificationUrlInput>;
}

export interface RegisterResponse {
    status: RegisterStatus;
    token?: Maybe<Scalars['String']['output']>;
}

export enum RegisterStatus {
    /** MUST_VERIFY_EMAIL */
    MustVerifyEmail = 'MUST_VERIFY_EMAIL',
    /** SUCCESS */
    Success = 'SUCCESS',
}

export interface ResendEmailVerificationInput {
    email: Scalars['String']['input'];
    verification_url?: InputMaybe<VerificationUrlInput>;
}

export interface ResendEmailVerificationResponse {
    status: ResendEmailVerificationStatus;
}

export enum ResendEmailVerificationStatus {
    /** EMAIL_SENT */
    EmailSent = 'EMAIL_SENT',
}

export interface ResetPasswordInput {
    email: Scalars['String']['input'];
    password: Scalars['String']['input'];
    password_confirmation: Scalars['String']['input'];
    token: Scalars['String']['input'];
}

export interface ResetPasswordResponse {
    message?: Maybe<Scalars['String']['output']>;
    status: ResetPasswordStatus;
}

export enum ResetPasswordStatus {
    /** PASSWORD_RESET */
    PasswordReset = 'PASSWORD_RESET',
}

/**
 * The url used to reset the password.
 * Use the `__EMAIL__` and `__TOKEN__` placeholders to inject the reset password email and token.
 *
 * e.g; `https://my-front-end.com?reset-password?email=__EMAIL__&token=__TOKEN__`
 */
export interface ResetPasswordUrlInput {
    url: Scalars['String']['input'];
}

/** Directions for ordering a list of records. */
export enum SortOrder {
    /** Sort records in ascending order. */
    Asc = 'ASC',
    /** Sort records in descending order. */
    Desc = 'DESC',
}

export interface Sponsor {
    id: Scalars['ID']['output'];
    link: Scalars['String']['output'];
    logo?: Maybe<Scalars['String']['output']>;
    name: Scalars['String']['output'];
    priority: Scalars['Int']['output'];
    text: Scalars['String']['output'];
}

export interface Team {
    contests: Array<Maybe<Contest>>;
    country: Scalars['String']['output'];
    fish: Array<Maybe<Fish>>;
    id: Scalars['ID']['output'];
    isActive: Scalars['Boolean']['output'];
    name: Scalars['String']['output'];
    users: Array<Maybe<User>>;
}

/** A paginated list of Team items. */
export interface TeamPaginator {
    /** A list of Team items. */
    data: Array<Team>;
    /** Pagination information about the list of items. */
    paginatorInfo: PaginatorInfo;
}

export interface TopCatch {
    fish: Fish;
    isTopCatch: Scalars['Boolean']['output'];
    team: Team;
}

export interface TopCatches {
    contest: Contest;
    topCatches: Array<Maybe<TopCatch>>;
}

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
    /** Only return trashed results. */
    Only = 'ONLY',
    /** Return both trashed and non-trashed results. */
    With = 'WITH',
    /** Only return non-trashed results. */
    Without = 'WITHOUT',
}

export interface UpdatePasswordInput {
    current_password: Scalars['String']['input'];
    password: Scalars['String']['input'];
    password_confirmation: Scalars['String']['input'];
}

export interface UpdatePasswordResponse {
    status: UpdatePasswordStatus;
}

export enum UpdatePasswordStatus {
    /** PASSWORD_UPDATED */
    PasswordUpdated = 'PASSWORD_UPDATED',
}

export interface User {
    createdAt: Scalars['DateTime']['output'];
    email: Scalars['String']['output'];
    emailVerifiedAt?: Maybe<Scalars['DateTime']['output']>;
    firstName: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    isAdmin: Scalars['Boolean']['output'];
    lastName: Scalars['String']['output'];
    teams: Array<Maybe<Team>>;
    updatedAt: Scalars['DateTime']['output'];
}

/** A paginated list of User items. */
export interface UserPaginator {
    /** A list of User items. */
    data: Array<User>;
    /** Pagination information about the list of items. */
    paginatorInfo: PaginatorInfo;
}

/**
 * The url used to verify the email address.
 * Use __ID__ and __HASH__ to inject values.
 *
 * e.g; `https://my-front-end.com/verify-email?id=__ID__&hash=__HASH__`
 *
 * If the API uses signed email verification urls
 * you must also use __EXPIRES__ and __SIGNATURE__
 *
 * e.g; `https://my-front-end.com/verify-email?id=__ID__&hash=__HASH__&expires=__EXPIRES__&signature=__SIGNATURE__`
 */
export interface VerificationUrlInput {
    url: Scalars['String']['input'];
}

export interface VerifyEmailInput {
    expires?: InputMaybe<Scalars['Int']['input']>;
    hash: Scalars['String']['input'];
    id: Scalars['ID']['input'];
    signature?: InputMaybe<Scalars['String']['input']>;
}

export interface WebContent {
    content?: Maybe<Scalars['String']['output']>;
    createdAt: Scalars['DateTime']['output'];
    id: Scalars['ID']['output'];
    title?: Maybe<Scalars['String']['output']>;
    updatedAt: Scalars['DateTime']['output'];
}

export type PaginatorInfoFragment = { count: number, currentPage: number, firstItem?: number | null, hasMorePages: boolean, lastItem?: number | null, lastPage: number, perPage: number, total: number };

export type ContestFragment = { id: string, name: string, rules: string, isCurrentlyActive: boolean, inTestmode: boolean, startsAt: any, endsAt: any, hoursTillClosing: number, contestFish: Array<{ id: string, name: string, photo: string, minimumLength: number, maxAmount: number, requiredToContinue: number, priority: number, contest: { id: string, name: string, isCurrentlyActive: boolean, inTestmode: boolean } }> };

export type ContestFishFragment = { id: string, name: string, photo: string, minimumLength: number, maxAmount: number, requiredToContinue: number, priority: number, contest: { id: string, name: string, isCurrentlyActive: boolean, inTestmode: boolean } };

export type TeamFragment = { id: string, isActive: boolean, name: string, country: string, users: Array<{ id: string, firstName: string, lastName: string } | null>, fish: Array<{ id: string, length?: number | null, latitude: string, longitude: string, photo?: string | null, state: FishStatusEnum, user: { id: string, firstName: string, lastName: string }, contestFish?: { id: string, name: string, photo: string } | null, team: { id: string, name: string } } | null>, contests: Array<{ id: string, name: string, isCurrentlyActive: boolean, inTestmode: boolean } | null> };

export type TeamQueryVariables = Exact<{
    id: Scalars['ID']['input'];
}>;


export type TeamQuery = { team: { id: string, isActive: boolean, name: string, country: string, users: Array<{ id: string, firstName: string, lastName: string } | null>, fish: Array<{ id: string, length?: number | null, latitude: string, longitude: string, photo?: string | null, state: FishStatusEnum, user: { id: string, firstName: string, lastName: string }, contestFish?: { id: string, name: string, photo: string } | null, team: { id: string, name: string } } | null>, contests: Array<{ id: string, name: string, isCurrentlyActive: boolean, inTestmode: boolean } | null> } };

export type ContestQueryVariables = Exact<{
    id: Scalars['ID']['input'];
}>;


export type ContestQuery = { contest: { id: string, name: string, rules: string, isCurrentlyActive: boolean, inTestmode: boolean, startsAt: any, endsAt: any, hoursTillClosing: number, contestFish: Array<{ id: string, name: string, photo: string, minimumLength: number, maxAmount: number, requiredToContinue: number, priority: number, contest: { id: string, name: string, isCurrentlyActive: boolean, inTestmode: boolean } }> } };

export type ContestRulesQueryVariables = Exact<{
    contestId: Scalars['ID']['input'];
}>;


export type ContestRulesQuery = { contest: { rules: string } };

export type FishFragment = { id: string, length?: number | null, latitude: string, longitude: string, photo?: string | null, state: FishStatusEnum, user: { id: string, firstName: string, lastName: string }, contestFish?: { id: string, name: string, photo: string } | null, team: { id: string, name: string } };

export type RegisterFishMutationVariables = Exact<{
    input: FishInput;
}>;


export type RegisterFishMutation = { registerFish: { id: string, length?: number | null, latitude: string, longitude: string, photo?: string | null, state: FishStatusEnum, user: { id: string, firstName: string, lastName: string }, contestFish?: { id: string, name: string, photo: string } | null, team: { id: string, name: string } } };

export type MenuWebContentFragment = { id: string, title: string, url?: string | null };

export type MenuSponsorFragment = { id: string, logo?: string | null, name: string };

export type GeneralDataFragment = { dynamicMenuItems: Array<{ id: string, title: string, url?: string | null } | null>, sponsors: Array<{ id: string, logo?: string | null, name: string } | null>, activeContest?: { id: string, name: string } | null };

export type GeneralDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GeneralDataQuery = { generalData: { dynamicMenuItems: Array<{ id: string, title: string, url?: string | null } | null>, sponsors: Array<{ id: string, logo?: string | null, name: string } | null>, activeContest?: { id: string, name: string } | null } };

export type LeaderboardEntryFragment = { rank: number, rankStatus: RankStatusEnum, points: number, hasBonus: boolean, team: { id: string, name: string, country: string, users: Array<{ id: string, firstName: string, lastName: string } | null> } };

export type LeaderboardQueryVariables = Exact<{
    contestId: Scalars['ID']['input'];
}>;


export type LeaderboardQuery = { leaderboard: { leaderboardEntries: Array<{ rank: number, rankStatus: RankStatusEnum, points: number, hasBonus: boolean, team: { id: string, name: string, country: string, users: Array<{ id: string, firstName: string, lastName: string } | null> } } | null>, contest: { id: string, name: string } } };

export type LeaderboardEntriesQueryVariables = Exact<{
    contestId: Scalars['ID']['input'];
}>;


export type LeaderboardEntriesQuery = { leaderboard: { leaderboardEntries: Array<{ rank: number, rankStatus: RankStatusEnum, points: number, hasBonus: boolean, team: { id: string, name: string, country: string, users: Array<{ id: string, firstName: string, lastName: string } | null> } } | null> } };

export type MessageEntryFragment = { id: string, topic?: string | null, title: string, message: string, createdAt: any };

export type MessageFragment = { id: string, topic?: string | null, title: string, message: string, content: string, createdAt: any };

export type MessagesQueryVariables = Exact<{
    first: Scalars['Int']['input'];
    page?: InputMaybe<Scalars['Int']['input']>;
}>;


export type MessagesQuery = { messages: { data: Array<{ id: string, topic?: string | null, title: string, message: string, createdAt: any }>, paginatorInfo: { count: number, currentPage: number, firstItem?: number | null, hasMorePages: boolean, lastItem?: number | null, lastPage: number, perPage: number, total: number } } };

export type MessageQueryVariables = Exact<{
    id: Scalars['ID']['input'];
}>;


export type MessageQuery = { message: { id: string, topic?: string | null, title: string, message: string, content: string, createdAt: any } };

export type NewsFragment = { id: string, title: string, content: string, image?: string | null, isActive: boolean, createdAt: any };

export type NewsQueryVariables = Exact<{
    contestId: Scalars['ID']['input'];
}>;


export type NewsQuery = { news: Array<{ id: string, title: string, content: string, image?: string | null, isActive: boolean, createdAt: any }> };

export type NewsItemQueryVariables = Exact<{
    id: Scalars['ID']['input'];
}>;


export type NewsItemQuery = { newsItem: { id: string, title: string, content: string, image?: string | null, isActive: boolean, createdAt: any } };

export type SponsorFragment = { id: string, name: string, logo?: string | null, link: string, text: string, priority: number };

export type SponsorsQueryVariables = Exact<{
    contestId: Scalars['ID']['input'];
}>;


export type SponsorsQuery = { sponsors: Array<{ id: string, name: string, logo?: string | null, link: string, text: string, priority: number }> };

export type SponsorQueryVariables = Exact<{
    id: Scalars['ID']['input'];
}>;


export type SponsorQuery = { sponsor: { id: string, name: string, logo?: string | null, link: string, text: string, priority: number } };

export type TopCatchFragment = { isTopCatch: boolean, fish: { id: string, length?: number | null, latitude: string, longitude: string, photo?: string | null, state: FishStatusEnum, user: { id: string, firstName: string, lastName: string }, contestFish?: { id: string, name: string, photo: string } | null, team: { id: string, name: string } }, team: { id: string, name: string, country: string, users: Array<{ id: string, firstName: string, lastName: string } | null> } };

export type TopCatchesQueryVariables = Exact<{
    contestId: Scalars['ID']['input'];
    limit?: InputMaybe<Scalars['Int']['input']>;
}>;


export type TopCatchesQuery = { topCatches: { topCatches: Array<{ isTopCatch: boolean, fish: { id: string, length?: number | null, latitude: string, longitude: string, photo?: string | null, state: FishStatusEnum, user: { id: string, firstName: string, lastName: string }, contestFish?: { id: string, name: string, photo: string } | null, team: { id: string, name: string } }, team: { id: string, name: string, country: string, users: Array<{ id: string, firstName: string, lastName: string } | null> } } | null>, contest: { id: string, name: string } } };

export type UserFragment = { id: string, isAdmin: boolean, firstName: string, lastName: string, email: string, emailVerifiedAt?: any | null, createdAt: any, updatedAt: any, teams: Array<{ id: string, isActive: boolean, name: string, country: string, users: Array<{ id: string, firstName: string, lastName: string } | null>, fish: Array<{ id: string, length?: number | null, latitude: string, longitude: string, photo?: string | null, state: FishStatusEnum, user: { id: string, firstName: string, lastName: string }, contestFish?: { id: string, name: string, photo: string } | null, team: { id: string, name: string } } | null>, contests: Array<{ id: string, name: string, isCurrentlyActive: boolean, inTestmode: boolean } | null> } | null> };

export type ForgotPasswordMutationVariables = Exact<{
    input: ForgotPasswordInput;
}>;


export type ForgotPasswordMutation = { forgotPassword: { status: ForgotPasswordStatus, message?: string | null } };

export type LoginMutationVariables = Exact<{
    input: LoginInput;
}>;


export type LoginMutation = { login: { token: string } };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { logout: { message: string, status: LogoutStatus } };

export type RegisterNotificationTokenMutationVariables = Exact<{
    delete: Scalars['Boolean']['input'];
    token: Scalars['String']['input'];
}>;


export type RegisterNotificationTokenMutation = { registerNotificationToken: boolean };

export type ResendEmailVerificationMutationVariables = Exact<{
    input: ResendEmailVerificationInput;
}>;


export type ResendEmailVerificationMutation = { resendEmailVerification: { status: ResendEmailVerificationStatus } };

export type UpdatePasswordMutationVariables = Exact<{
    input: UpdatePasswordInput;
}>;


export type UpdatePasswordMutation = { updatePassword: { status: UpdatePasswordStatus } };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { me?: { id: string, isAdmin: boolean, firstName: string, lastName: string, email: string, emailVerifiedAt?: any | null, createdAt: any, updatedAt: any, teams: Array<{ id: string, isActive: boolean, name: string, country: string, users: Array<{ id: string, firstName: string, lastName: string } | null>, fish: Array<{ id: string, length?: number | null, latitude: string, longitude: string, photo?: string | null, state: FishStatusEnum, user: { id: string, firstName: string, lastName: string }, contestFish?: { id: string, name: string, photo: string } | null, team: { id: string, name: string } } | null>, contests: Array<{ id: string, name: string, isCurrentlyActive: boolean, inTestmode: boolean } | null> } | null> } | null };

export type UserQueryVariables = Exact<{
    email?: InputMaybe<Scalars['String']['input']>;
    id?: InputMaybe<Scalars['ID']['input']>;
}>;


export type UserQuery = { user?: { id: string, isAdmin: boolean, firstName: string, lastName: string, email: string, emailVerifiedAt?: any | null, createdAt: any, updatedAt: any, teams: Array<{ id: string, isActive: boolean, name: string, country: string, users: Array<{ id: string, firstName: string, lastName: string } | null>, fish: Array<{ id: string, length?: number | null, latitude: string, longitude: string, photo?: string | null, state: FishStatusEnum, user: { id: string, firstName: string, lastName: string }, contestFish?: { id: string, name: string, photo: string } | null, team: { id: string, name: string } } | null>, contests: Array<{ id: string, name: string, isCurrentlyActive: boolean, inTestmode: boolean } | null> } | null> } | null };

export type UsersQueryVariables = Exact<{
    first: Scalars['Int']['input'];
    page: Scalars['Int']['input'];
    email?: InputMaybe<Scalars['String']['input']>;
    firstName?: InputMaybe<Scalars['String']['input']>;
    lastName?: InputMaybe<Scalars['String']['input']>;
}>;


export type UsersQuery = { users: { data: Array<{ id: string, isAdmin: boolean, firstName: string, lastName: string, email: string, emailVerifiedAt?: any | null, createdAt: any, updatedAt: any, teams: Array<{ id: string, isActive: boolean, name: string, country: string, users: Array<{ id: string, firstName: string, lastName: string } | null>, fish: Array<{ id: string, length?: number | null, latitude: string, longitude: string, photo?: string | null, state: FishStatusEnum, user: { id: string, firstName: string, lastName: string }, contestFish?: { id: string, name: string, photo: string } | null, team: { id: string, name: string } } | null>, contests: Array<{ id: string, name: string, isCurrentlyActive: boolean, inTestmode: boolean } | null> } | null> }>, paginatorInfo: { count: number, currentPage: number, firstItem?: number | null, hasMorePages: boolean, lastItem?: number | null, lastPage: number, perPage: number, total: number } } };

export type WebContentFragment = { id: string, title?: string | null, content?: string | null, createdAt: any, updatedAt: any };

export type WebContentQueryVariables = Exact<{
    id: Scalars['ID']['input'];
}>;


export type WebContentQuery = { webContent: { id: string, title?: string | null, content?: string | null, createdAt: any, updatedAt: any } };

export const PaginatorInfoFragmentDoc = gql`
    fragment PaginatorInfo on PaginatorInfo {
  count
  currentPage
  firstItem
  hasMorePages
  lastItem
  lastPage
  perPage
  total
}
    `;
export const ContestFishFragmentDoc = gql`
    fragment ContestFish on ContestFish {
  id
  name
  photo
  minimumLength
  maxAmount
  requiredToContinue
  priority
  contest {
    id
    name
    isCurrentlyActive
    inTestmode
  }
}
    `;
export const ContestFragmentDoc = gql`
    fragment Contest on Contest {
  id
  name
  rules
  isCurrentlyActive
  inTestmode
  startsAt
  endsAt
  hoursTillClosing
  contestFish {
    ...ContestFish
  }
}
    ${ContestFishFragmentDoc}`;
export const MenuWebContentFragmentDoc = gql`
    fragment MenuWebContent on MenuWebContent {
  id
  title
  url
}
    `;
export const MenuSponsorFragmentDoc = gql`
    fragment MenuSponsor on MenuSponsor {
  id
  logo
  name
}
    `;
export const GeneralDataFragmentDoc = gql`
    fragment GeneralData on GeneralData {
  dynamicMenuItems {
    ...MenuWebContent
  }
  sponsors {
    ...MenuSponsor
  }
  activeContest {
    id
    name
  }
}
    ${MenuWebContentFragmentDoc}
${MenuSponsorFragmentDoc}`;
export const LeaderboardEntryFragmentDoc = gql`
    fragment LeaderboardEntry on LeaderboardEntry {
  rank
  rankStatus
  points
  hasBonus
  team {
    id
    name
    country
    users {
      id
      firstName
      lastName
    }
  }
}
    `;
export const MessageEntryFragmentDoc = gql`
    fragment MessageEntry on MessageEntry {
  id
  topic
  title
  message
  createdAt
}
    `;
export const MessageFragmentDoc = gql`
    fragment Message on Message {
  id
  topic
  title
  message
  content
  createdAt
}
    `;
export const NewsFragmentDoc = gql`
    fragment News on News {
  id
  title
  content
  image
  isActive
  createdAt
}
    `;
export const SponsorFragmentDoc = gql`
    fragment Sponsor on Sponsor {
  id
  name
  logo
  link
  text
  priority
}
    `;
export const FishFragmentDoc = gql`
    fragment Fish on Fish {
  id
  length
  latitude
  longitude
  photo
  state
  user {
    id
    firstName
    lastName
  }
  contestFish {
    id
    name
    photo
  }
  team {
    id
    name
  }
}
    `;
export const TopCatchFragmentDoc = gql`
    fragment TopCatch on TopCatch {
  isTopCatch
  fish {
    ...Fish
  }
  team {
    id
    name
    country
    users {
      id
      firstName
      lastName
    }
  }
}
    ${FishFragmentDoc}`;
export const TeamFragmentDoc = gql`
    fragment Team on Team {
  id
  isActive
  name
  country
  users {
    id
    firstName
    lastName
  }
  fish {
    ...Fish
  }
  contests {
    id
    name
    isCurrentlyActive
    inTestmode
  }
}
    ${FishFragmentDoc}`;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  isAdmin
  firstName
  lastName
  email
  emailVerifiedAt
  createdAt
  updatedAt
  teams {
    ...Team
  }
}
    ${TeamFragmentDoc}`;
export const WebContentFragmentDoc = gql`
    fragment WebContent on WebContent {
  id
  title
  content
  createdAt
  updatedAt
}
    `;
export const TeamDocument = gql`
    query team($id: ID!) {
  team(id: $id) {
    ...Team
  }
}
    ${TeamFragmentDoc}`;

@Injectable({
    providedIn: 'root',
})
export class TeamQueryService extends Apollo.Query<TeamQuery, TeamQueryVariables> {
    override document = TeamDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ContestDocument = gql`
    query contest($id: ID!) {
  contest(id: $id) {
    ...Contest
  }
}
    ${ContestFragmentDoc}`;

@Injectable({
    providedIn: 'root',
})
export class ContestQueryService extends Apollo.Query<ContestQuery, ContestQueryVariables> {
    override document = ContestDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ContestRulesDocument = gql`
    query contestRules($contestId: ID!) {
  contest(id: $contestId) {
    rules
  }
}
    `;

@Injectable({
    providedIn: 'root',
})
export class ContestRulesQueryService extends Apollo.Query<ContestRulesQuery, ContestRulesQueryVariables> {
    override document = ContestRulesDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const RegisterFishDocument = gql`
    mutation registerFish($input: FishInput!) {
  registerFish(input: $input) {
    ...Fish
  }
}
    ${FishFragmentDoc}`;

@Injectable({
    providedIn: 'root',
})
export class RegisterFishMutationService extends Apollo.Mutation<RegisterFishMutation, RegisterFishMutationVariables> {
    override document = RegisterFishDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const GeneralDataDocument = gql`
    query generalData {
  generalData {
    ...GeneralData
  }
}
    ${GeneralDataFragmentDoc}`;

@Injectable({
    providedIn: 'root',
})
export class GeneralDataQueryService extends Apollo.Query<GeneralDataQuery, GeneralDataQueryVariables> {
    override document = GeneralDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const LeaderboardDocument = gql`
    query leaderboard($contestId: ID!) {
  leaderboard(contestId: $contestId) {
    leaderboardEntries {
      ...LeaderboardEntry
    }
    contest {
      id
      name
    }
  }
}
    ${LeaderboardEntryFragmentDoc}`;

@Injectable({
    providedIn: 'root',
})
export class LeaderboardQueryService extends Apollo.Query<LeaderboardQuery, LeaderboardQueryVariables> {
    override document = LeaderboardDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const LeaderboardEntriesDocument = gql`
    query leaderboardEntries($contestId: ID!) {
  leaderboard(contestId: $contestId) {
    leaderboardEntries {
      ...LeaderboardEntry
    }
  }
}
    ${LeaderboardEntryFragmentDoc}`;

@Injectable({
    providedIn: 'root',
})
export class LeaderboardEntriesQueryService extends Apollo.Query<LeaderboardEntriesQuery, LeaderboardEntriesQueryVariables> {
    override document = LeaderboardEntriesDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MessagesDocument = gql`
    query messages($first: Int!, $page: Int) {
  messages(first: $first, page: $page) {
    data {
      ...MessageEntry
    }
    paginatorInfo {
      ...PaginatorInfo
    }
  }
}
    ${MessageEntryFragmentDoc}
${PaginatorInfoFragmentDoc}`;

@Injectable({
    providedIn: 'root',
})
export class MessagesQueryService extends Apollo.Query<MessagesQuery, MessagesQueryVariables> {
    override document = MessagesDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MessageDocument = gql`
    query message($id: ID!) {
  message(id: $id) {
    ...Message
  }
}
    ${MessageFragmentDoc}`;

@Injectable({
    providedIn: 'root',
})
export class MessageQueryService extends Apollo.Query<MessageQuery, MessageQueryVariables> {
    override document = MessageDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const NewsDocument = gql`
    query news($contestId: ID!) {
  news(contestId: $contestId) {
    ...News
  }
}
    ${NewsFragmentDoc}`;

@Injectable({
    providedIn: 'root',
})
export class NewsQueryService extends Apollo.Query<NewsQuery, NewsQueryVariables> {
    override document = NewsDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const NewsItemDocument = gql`
    query newsItem($id: ID!) {
  newsItem(id: $id) {
    ...News
  }
}
    ${NewsFragmentDoc}`;

@Injectable({
    providedIn: 'root',
})
export class NewsItemQueryService extends Apollo.Query<NewsItemQuery, NewsItemQueryVariables> {
    override document = NewsItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const SponsorsDocument = gql`
    query sponsors($contestId: ID!) {
  sponsors(contestId: $contestId) {
    ...Sponsor
  }
}
    ${SponsorFragmentDoc}`;

@Injectable({
    providedIn: 'root',
})
export class SponsorsQueryService extends Apollo.Query<SponsorsQuery, SponsorsQueryVariables> {
    override document = SponsorsDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const SponsorDocument = gql`
    query sponsor($id: ID!) {
  sponsor(id: $id) {
    ...Sponsor
  }
}
    ${SponsorFragmentDoc}`;

@Injectable({
    providedIn: 'root',
})
export class SponsorQueryService extends Apollo.Query<SponsorQuery, SponsorQueryVariables> {
    override document = SponsorDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const TopCatchesDocument = gql`
    query topCatches($contestId: ID!, $limit: Int) {
  topCatches(contestId: $contestId, limit: $limit) {
    topCatches {
      ...TopCatch
    }
    contest {
      id
      name
    }
  }
}
    ${TopCatchFragmentDoc}`;

@Injectable({
    providedIn: 'root',
})
export class TopCatchesQueryService extends Apollo.Query<TopCatchesQuery, TopCatchesQueryVariables> {
    override document = TopCatchesDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($input: ForgotPasswordInput!) {
  forgotPassword(input: $input) {
    status
    message
  }
}
    `;

@Injectable({
    providedIn: 'root',
})
export class ForgotPasswordMutationService extends Apollo.Mutation<ForgotPasswordMutation, ForgotPasswordMutationVariables> {
    override document = ForgotPasswordDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const LoginDocument = gql`
    mutation login($input: LoginInput!) {
  login(input: $input) {
    token
  }
}
    `;

@Injectable({
    providedIn: 'root',
})
export class LoginMutationService extends Apollo.Mutation<LoginMutation, LoginMutationVariables> {
    override document = LoginDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const LogoutDocument = gql`
    mutation logout {
  logout {
    message
    status
  }
}
    `;

@Injectable({
    providedIn: 'root',
})
export class LogoutMutationService extends Apollo.Mutation<LogoutMutation, LogoutMutationVariables> {
    override document = LogoutDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const RegisterNotificationTokenDocument = gql`
    mutation registerNotificationToken($delete: Boolean!, $token: String!) {
  registerNotificationToken(delete: $delete, token: $token)
}
    `;

@Injectable({
    providedIn: 'root',
})
export class RegisterNotificationTokenMutationService extends Apollo.Mutation<RegisterNotificationTokenMutation, RegisterNotificationTokenMutationVariables> {
    override document = RegisterNotificationTokenDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ResendEmailVerificationDocument = gql`
    mutation resendEmailVerification($input: ResendEmailVerificationInput!) {
  resendEmailVerification(input: $input) {
    status
  }
}
    `;

@Injectable({
    providedIn: 'root',
})
export class ResendEmailVerificationMutationService extends Apollo.Mutation<ResendEmailVerificationMutation, ResendEmailVerificationMutationVariables> {
    override document = ResendEmailVerificationDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdatePasswordDocument = gql`
    mutation updatePassword($input: UpdatePasswordInput!) {
  updatePassword(input: $input) {
    status
  }
}
    `;

@Injectable({
    providedIn: 'root',
})
export class UpdatePasswordMutationService extends Apollo.Mutation<UpdatePasswordMutation, UpdatePasswordMutationVariables> {
    override document = UpdatePasswordDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MeDocument = gql`
    query me {
  me {
    ...User
  }
}
    ${UserFragmentDoc}`;

@Injectable({
    providedIn: 'root',
})
export class MeQueryService extends Apollo.Query<MeQuery, MeQueryVariables> {
    override document = MeDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UserDocument = gql`
    query user($email: String, $id: ID) {
  user(email: $email, id: $id) {
    ...User
  }
}
    ${UserFragmentDoc}`;

@Injectable({
    providedIn: 'root',
})
export class UserQueryService extends Apollo.Query<UserQuery, UserQueryVariables> {
    override document = UserDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UsersDocument = gql`
    query users($first: Int!, $page: Int!, $email: String, $firstName: String, $lastName: String) {
  users(
    first: $first
    page: $page
    email: $email
    firstName: $firstName
    lastName: $lastName
  ) {
    data {
      ...User
    }
    paginatorInfo {
      ...PaginatorInfo
    }
  }
}
    ${UserFragmentDoc}
${PaginatorInfoFragmentDoc}`;

@Injectable({
    providedIn: 'root',
})
export class UsersQueryService extends Apollo.Query<UsersQuery, UsersQueryVariables> {
    override document = UsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const WebContentDocument = gql`
    query webContent($id: ID!) {
  webContent(id: $id) {
    ...WebContent
  }
}
    ${WebContentFragmentDoc}`;

@Injectable({
    providedIn: 'root',
})
export class WebContentQueryService extends Apollo.Query<WebContentQuery, WebContentQueryVariables> {
    override document = WebContentDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}